<template>

<!-- 1000 720  -->

  <div id="app" class="">
    <Headerr></Headerr>
    <KeepAlive>
      <router-view></router-view>
    </KeepAlive>
   <Foot></Foot> 
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Mde from './components/Mde.vue'
import Foot from './components/Foot.vue'
import Headerr from './components/Header.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    Mde,
    Foot,
    Headerr,
  },
  

  methods: {

  }
}
</script>

<style>

@font-face {
            font-family: "afont";
            src: url("../src/assets/sty/VerminVibes2.otf") format("opentype");
        }
@font-face {
            font-family: "jetbrainmono";
            src: url("../src/assets/sty/jetbrainmono.ttf") format("opentype");
        }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  font-weight: 400;
  
}

*{

  scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

body {
  margin: 0 auto;
  min-width: 320px;
  min-height: 100vh;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  background-color: var(--bgColor) !important;

}

.md-img{
  max-width: 100%;
}

.night-mode {
  --background-color: #333; 
  --text-color: #fff; 
}
.forceCenter {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}
.gray {
  /* 2024/02 */
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-filter: gray;
    filter: gray;
    -webkit-filter: progid:dximagetransform.microsoft.basicimage(grayscale=1);
    filter: progid:dximagetransform.microsoft.basicimage(grayscale=1);
}


</style>

// axios-config.js
import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api.xconst.com',  // 指定根请求的URL/
  // baseURL: 'http://193.112.179.151:9900',  // 指定根请求的URL/
  withCredentials: true, // 添加此项以启用跨域请求时携带凭据
  // 其他axios配置选项可以在这里添加
});

export default instance;

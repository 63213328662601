<template>
    <div id="asjnfhabfha">
        <Loading v-show="loading"></Loading>

        <div v-show="!loading">
            <div>
                <button @click="dmIndex -= 500; changePageIndex(-1)">《《《</button>
                <button @click="dmIndex -= 100; changePageIndex(-1)">《《</button>
                <button @click="dmIndex -= 50; changePageIndex(-1)">《</button>
                {{ dmIndex + 1 }}&nbsp;|&nbsp;{{ dms.length }}
                <button @click="dmIndex += 50; changePageIndex(1)">》</button>
                <button @click="dmIndex += 100; changePageIndex(1)">》》</button>
                <button @click="dmIndex += 500; changePageIndex(1)">》》》</button>
                <button @click="openSettings">设置</button>

            </div>
            <div v-if="isSetting">
                <div>
                    <label>
                        <input type="radio" v-model="isAsc" :value="1"> asc
                    </label>
                    <label>
                        <input type="radio" v-model="isAsc" :value="0"> desc
                    </label>
                    {{ isAsc }}
                </div>
                <div>
                    <label>
                        <input type="radio" v-model="field" :value="0"> 过期
                    </label>
                    <label>
                        <input type="radio" v-model="field" :value="1"> 注册
                    </label>
                    <label>
                        <input type="radio" v-model="field" :value="2"> 扫描
                    </label>
                    <label>
                        <input type="radio" v-model="field" :value="3"> 审查
                    </label>
                    {{ field }}
                </div>

            </div>

            <a target="_blank" :href="`http://${dm.name}.com`" ref="MainBannerWrapper2" id="MainBannerWrapper2">
                <div id="domainName">
                    {{ dm.name }}
                </div>
            </a>
            <div id="moreInfo">
                <a target="_blank" title="icann whois"  :href="`https://lookup.icann.org/whois/en?q=${dm.name}.com&t=a`">
                    <img src="https://assets.apilayer.com/apis/whois.png" alt="">
                </a>
                <a title="google without tld" target="_blank" :href="`https://www.google.com.hk/search?q=${dm.name}`">
                    <img src="https://m.media-amazon.com/images/I/41HeL2cy9LL.png" alt="">
                </a>
                <a title="google with tld" target="_blank" :href="`https://www.google.com.hk/search?q=${dm.name}.com`">
                    <img src="https://m.media-amazon.com/images/I/41HeL2cy9LL.png" alt="">
                </a>
            </div>
            <table>

                <tbody>
                    <tr>
                        <td>exTime</td>
                        <td>{{ dm.exTime.slice(0,-10) }}</td>
                    </tr>
                    <tr>
                        <td>regTime</td>
                        <td>{{ dm.regTime.slice(0,-10) }}</td>
                    </tr>
                    <tr>
                        <td>scanTime</td>
                        <td>{{ dm.scanTime.slice(0,-10) }}</td>
                    </tr>
                    <tr>
                        <td>examineTime</td>
                        <td>{{ dm.examineTime.slice(0,-10) }}</td>
                    </tr>
                    
                </tbody>
            </table>

            <div id="attPanel">

                <div id="whoisControl">
                    <div class="controlBt" @click="whoisNow">更新</div>
                    <div class="controlBt" @click="retrieveOne">刷新</div>
                </div>
                <div id="infoControl">
                    <div @click="isAddingInfo = !isAddingInfo" v-if="!isAddingInfo">
                        {{ dm.info ? dm.info : "添加备注" }}
                    </div>
                    <div v-else>
                        <input v-model="AddingInfo" placeholder="添加备注" type="text" />
                        <button @click="isAddingInfo = !isAddingInfo; cancleUpateInfo()">取消</button>
                        <button @click="isAddingInfo = !isAddingInfo; confirmUpateInfo()">确认</button>
                    </div>

                </div>
                <div>
                    <div class="controlBt" id="concernControl" @click="changeConcern">关注 {{ dm.concern }}</div>
                </div>
                <div id="delControl">
                    <div class="controlBt" v-if="!confirmDel" @click="confirmDel = true">删除</div>
                    <template v-if="confirmDel">

                        <div class="controlBt" @click="confirmDel = false">取消删除</div>
                        <div class="controlBt" style="color: red;font-weight: bold;" @click="confirmDelExe">确认删除</div>
                    </template>


                </div>

                <div id="indexControl">
                    <div class="controlBt" @click="changePageIndex(-1)">上一个</div>
                    <div class="controlBt" @click="examine">检查</div>
                    <div class="controlBt" @click="changePageIndex(1)">下一个</div>
                </div>

            </div>
            <div >
                        <input v-model="domainToBeInsert" placeholder="domain" type="text" />
                        <button @click=" domainToBeInsert = ''">取消</button>
                        <button @click=" domainInsert()">确认</button>
            </div>
        </div>

    </div>

</template>

<script>
import Loading from './Loading.vue'

import { getRandomBackground } from '../components/gd'

export default {
    components: {
        Loading,
    },
    data() {
        return {
            // ids: [{ "id": 1, "name": "chenyifaer" }, { "id": 2, "name": "actHard" }, { "id": 3, "name": "aCtSoft" }, { "id": 4, "name": "actGreen" }, { "id": 5, "name": "acTGray" }, { "id": 6, "name": "RunZeal" }, { "id": 7, "name": "PyZeal" }, { "id": 8, "name": "GoZeal" }, { "id": 9, "name": "JavaZeal" }, { "id": 10, "name": "RustZeal" }],
            dms: [
                {
                    "id": 1,
                    "name": "chenyifaer",
                    "tld": "com",
                    "info": null,
                    "exTime": "2027-03-01T19:07:20",
                    "regTime": "2017-03-01T19:07:20",
                    "existed": null,
                    "length": 10,
                    "rawkey": "chenyifaer",
                    "raw": "2027-03-01 19:07:20",
                    "scanTime": "2025-01-21T09:34:08"
                },
                {
                    "id": 2,
                    "name": "actHard",
                    "tld": "com",
                    "info": null,
                    "exTime": "2025-03-22T17:45:10",
                    "regTime": "2006-03-22T18:45:10",
                    "existed": null,
                    "length": 7,
                    "rawkey": "actHard",
                    "raw": "2025-03-22 17:45:10",
                    "scanTime": "2025-01-21T09:34:08"
                },
                {
                    "id": 3,
                    "name": "aCtSoft",
                    "tld": "com",
                    "info": null,
                    "exTime": "2032-02-08T21:43:38",
                    "regTime": "1996-11-06T05:00:00",
                    "existed": null,
                    "length": 7,
                    "rawkey": "aCtSoft",
                    "raw": "[datetime.datetime(2032, 2, 8, 21, 43, 38), datetime.datetime(2032, 2, 8, 7, 0)]",
                    "scanTime": "2025-01-21T09:34:08"
                },
                {
                    "id": 4,
                    "name": "actGreen",
                    "tld": "com",
                    "info": null,
                    "exTime": "2025-03-25T15:16:02",
                    "regTime": "2002-03-25T16:16:02",
                    "existed": null,
                    "length": 8,
                    "rawkey": "actGreen",
                    "raw": "2025-03-25 15:16:02",
                    "scanTime": "2025-01-21T09:34:08"
                },
                {
                    "id": 5,
                    "name": "acTGray",
                    "tld": "com",
                    "info": null,
                    "exTime": "2025-11-11T03:29:25",
                    "regTime": "2024-11-11T03:29:25",
                    "existed": null,
                    "length": 7,
                    "rawkey": "acTGray",
                    "raw": null,
                    "scanTime": "2025-01-21T09:34:08"
                },
                {
                    "id": 6,
                    "name": "RunZeal",
                    "tld": "com",
                    "info": "1",
                    "exTime": "2025-06-11T09:45:12",
                    "regTime": "2012-06-11T09:45:12",
                    "existed": null,
                    "length": 7,
                    "rawkey": "RunZeal",
                    "raw": "2025-06-11 09:45:12",
                    "scanTime": "2025-01-21T09:34:08"
                },
                {
                    "id": 7,
                    "name": "PyZeal",
                    "tld": "com",
                    "info": null,
                    "exTime": null,
                    "regTime": null,
                    "existed": null,
                    "length": 6,
                    "rawkey": "PyZeal",
                    "raw": null,
                    "scanTime": "2025-01-21T09:34:08"
                },
                {
                    "id": 8,
                    "name": "GoZeal",
                    "tld": "com",
                    "info": null,
                    "exTime": "2027-06-06T18:20:13",
                    "regTime": "2017-06-06T18:20:13",
                    "existed": null,
                    "length": 6,
                    "rawkey": "GoZeal",
                    "raw": "2027-06-06 18:20:13",
                    "scanTime": "2025-01-21T09:34:02"
                },
                {
                    "id": 9,
                    "name": "JavaZeal",
                    "tld": "com",
                    "info": null,
                    "exTime": "2025-12-29T23:24:59",
                    "regTime": "2017-12-29T23:24:59",
                    "existed": null,
                    "length": 8,
                    "rawkey": "JavaZeal",
                    "raw": "2024-12-29 23:24:59",
                    "scanTime": "2025-01-21T09:34:02"
                },
                {
                    "id": 10,
                    "name": "RustZeal",
                    "tld": "com",
                    "info": null,
                    "exTime": null,
                    "regTime": null,
                    "existed": null,
                    "length": 8,
                    "rawkey": "RustZeal",
                    "raw": null,
                    "scanTime": "2025-01-21T09:33:53"
                }
            ],
            dm: {},
            dmIndex: 0,
            pageSize: 100,
            loading: true,
            isAddingInfo: false,
            AddingInfo: '',
            batchSize: 30,

            confirmDel: false,
            isSetting: false,
            isAsc: 0,
            field : 3,
            domainToBeInsert:'',
        }
    },
    methods: {
        examine(){
            this.loading = true
            this.$axios.get(`/api/dm/has-examine/${this.dm.id}`)
                .then(res => {
                    

                    // this.loading = false

                    this.retrieveOne()

                })
        },
        openSettings() {
            this.isSetting = !this.isSetting
            if(this.isSetting == false){
                this.myInitButton()
            }
        },
        domainInsert(){
            this.loading = true
            this.$axios.get(`/api/dm/add-with-whois/${this.domainToBeInsert}`)
                .then(res => {
                    this.loading = false
                    this.domainToBeInsert = ''
                })

            
        },
        randomBg() {
            const q = getRandomBackground()
            this.$refs.MainBannerWrapper2.style.backgroundImage = q.gradient
        },
        initDms() {
            this.$axios.post("/api/dm/get/simple",{asc:this.isAsc,sort:this.field})
                .then(res => {
                    console.log(res)
                    this.dms = res.data
                    this.dms.forEach(obj => {
                        obj.isSimple = true;
                    })
                    this.fillDmFromNet();
                })
        },
        confirmDelExe() {
            this.loading = true
            this.$axios.get(`/api/dm/del/one/${this.dm.id}`)
                .then(res => {
                    console.log("deleteds")
                    this.dms.splice(this.dmIndex, 1);
                    this.confirmDel = false
                    this.dm = this.dms[this.dmIndex]
                    this.loading = false

                })
        },
        confirmUpateInfo() {
            console.log(123)
            this.dm.info = this.AddingInfo
            this.updateNow().then(_ => {
                this.AddingInfo = ""
            })
        },
        cancleUpateInfo() {
            this.AddingInfo = ""
        },
        fillDmFromNet() {
            this.loading = true
            let ids = [];
            let i = this.dmIndex - 2 * this.batchSize;
            for (; i < this.dms.length && i < this.dmIndex + 2 * this.batchSize; i++) {
                if (i < 0) {
                    continue
                }
                let hasLoaded = this.isLoadedFromNet(i)
                if (hasLoaded) {
                    continue
                }
                ids.push(this.dms[i].id)
            }


            if (ids.length == 0) {
                return
            }
            this.$axios.post(`/api/dm/get/mul`, ids)
                .then(res => {
                    // console.log(res)
                    // this.ids = res.data

                    console.log(res.data)
                    res.data.forEach(dm => {
                        let id = dm.id
                        let index = 0;

                        for (; index < this.dms.length; index++) {
                            if (this.dms[index].id == id) {
                                this.$set(this.dms, index, { ...dm, isSimple: false });
                                return;
                            }
                        }
                    })

                    // this.$set(this.dms, this.dmIndex, { ...res.data, isSimple: false });
                    this.dm = this.dms[this.dmIndex]
                    this.loading = false
                })
        },
        fillDmFromNetOld() {
            return
            let id = this.dms[this.dmIndex].id
            this.$axios.get(`/api/dm/get/one/${id}`)
                .then(res => {
                    // console.log(res)
                    // this.ids = res.data
                    this.$set(this.dms, this.dmIndex, { ...res.data, isSimple: false });
                    this.dm = this.dms[this.dmIndex]
                })
        },
        changeConcern() {
            this.dm.concern = !this.dm.concern

            this.updateNow()
        },
        whoisNow() {
            this.loading = true
            this.$axios.get(`/api/dm/update-whois/${this.dm.id}`)
                .then(res => {
                    this.loading = false
                })
        },
        retrieveOne() {
            this.loading = true
            this.$axios.get(`/api/dm/get/one/${this.dm.id}`)
                .then(res => {
                    console.log(res)
                    this.loading = false
                    let dm = res.data
                    let index = 0;
                    let id = res.data.id
                    for (; index < this.dms.length; index++) {
                        if (this.dms[index].id == id) {
                            this.$set(this.dms, index, { ...dm, isSimple: false });
                            break;
                        }
                    }
                    this.dm = this.dms[this.dmIndex]

                })
        },
        updateNow() {
            this.loading = true
            return this.$axios.post(`/api/dm/update/one`, this.dm)
                .then(res => {
                    console.log(res)
                    console.log("atgh")
                    this.fillDmFromNet()
                    this.loading = false
                })
        },
        changePageIndex(direction) {
            let sfasfa = this.dmIndex;
            if (direction > 0) {
                sfasfa++;
            } else {
                sfasfa--;
            }
            if (sfasfa == this.dms.length || sfasfa == -1) {
                return
            }
            this.dmIndex = sfasfa;
        },
        isLoadedFromNet(indexOfList) {
            if (indexOfList >= this.dms.length || indexOfList < 0) {
                return true; //保守一点
            }
            let obj = this.dms[indexOfList]
            return !obj.isSimple
        },
        myInitButton(){
            this.initDms();
            this.randomBg()
        }
    },
    mounted() {
        this.myInitButton()
        // this.initFirstPage();
    },
    watch: {
        dmIndex(_, __) {
            this.randomBg()
            this.dm = this.dms[this.dmIndex]

            let hasLoadedCur = this.isLoadedFromNet(this.dmIndex)
            let hasLoadedCurL = this.isLoadedFromNet(this.dmIndex - this.batchSize)
            let hasLoadedCurR = this.isLoadedFromNet(this.dmIndex + this.batchSize)
            if (hasLoadedCur && hasLoadedCurL && hasLoadedCurR) {
                return
            }
            this.fillDmFromNet()

        }
    }
}
</script>



<style lang="less" scoped>
#asjnfhabfha {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-top: 20px;
}

#MainBannerWrapper2 {

    margin: 10px;
    display: block;
    text-decoration: none;

    #domainName {
        color: aliceblue;
        font-size: 36px;
        font-weight: bolder;
        font-family: jetbrainmono, afont, serif;
        backdrop-filter: blur(10px);
        background-color: rgba(0, 0, 0, 0.387);
        text-align: center;
    }
}

#attPanel {

    >div {
        margin: 5px;
        display: flex;
        justify-content: space-around;
    }

    .controlBt {
        text-align: center;
        border: 1px solid wheat;
        border-radius: 5px;
        padding: 5px 10px;
        margin: 5px;
    }
}
#moreInfo{
    img{
        width: 50px;
    }
}
</style>
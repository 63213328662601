import VueRouter from 'vue-router'

import HelloWorld from "../components/HelloWorld.vue";
import Mde from "../components/Mde.vue";
import Detail from "../components/Detail.vue";
import Blog from "../components/Blog.vue";
import Index from "../components/Index.vue";
import Login from "../components/Login.vue";
import About from "../components/About.vue";
import Bin from "../components/Bin.vue";
import Dm from "../components/Dm.vue";

const rt= new VueRouter({
	routes: [
		//Todo 名称相同了
		{
			path: '/',
			name: 'init',
			redirect:"/blog",
		},
		{
			path: '/index',
			name: 'index',
			component: Index,
		},
		{
			path: '/bin',
			name: 'bin',
			component: Bin,
		},
		{
			path: '/login',
			name: 'login',
			component: Login,
		},
		{
			path: '/dm',
			name: 'dm',
			component: Dm,
		},
		{
			path: '/about',
			name: 'about',
			component: About,
		},
		{
			path: '/blog',
			name: 'blog',
			component: Blog,
			children: [
				{
					path: '/',
					name: 'blog2',
					component: HelloWorld,
					meta: { keepAlive: true },
					props: true,
				},
				{
					path: 'edit/:id',
					name: 'edit',
					component: Mde,
					props: true,

				},
				{
					path: 'write',
					name: 'write',
					component: Mde,
				},

				{
					path: 'detail/:id',
					name: 'Detail',
					component: Detail,
					props: true,
				},
			]
		},




	]
})

// rt.beforeEach((to,from,next)=>{
// 	next(vm=>{
// 		console.log(vm)
// 		console.log("vm")
// 		vm.$store.state.activeName=to.name

// 	})
// })

export default rt;